<template>
  <NuxtLink
    :class="getClasses"
    :to="linkObject"
  >
    <slot></slot>
    <span v-if="theme !== 'blank'" class="w-[2.5rem] h-[2.5rem] rounded-full flex items-center justify-center absolute top-[50%] -translate-y-[50%] bottom-0 right-[.25rem]" :class="getIconColour">
      <ArrowRightIcon class="w-4"/>
    </span>
  </NuxtLink>
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import ArrowRightIcon from "~/components/global/Icons/ArrowRightIcon.vue";

  const props = defineProps({
    theme: {
      default: 'primary',
      type: String
    },
    linkObject: {
      default: {},
      validator: (value) => {
        return typeof value === 'object' || typeof value === 'string';
      }
    }
  });

  const getClasses = computed(() => {
    let classes = 'bg-grey rounded-full px-5 py-3.5 inline-flex items-center min-w-[12rem] text-sm font-medium relative shadow-sm group';

    switch (props.theme) {
      case 'primary' :
        classes += ' bg-primary text-white';
      break;
      case 'secondary' :
        classes += ' bg-secondary text-primary';
        break;
      case 'grey' :
        classes += ' bg-grey text-primary hover:bg-primary hover:text-white transition';
        break;
      case 'white' :
        classes += ' bg-white text-primary hover:bg-primary hover:text-white transition';
        break;
      case 'outline-secondary' :
        classes += ' border-2 border-secondary text-secondary';
        break;
      case 'outline-primary' :
        classes += ' border-2 border-primary text-primary';
        break;
      case 'outline-white' :
        classes += ' bg-transparent border-2 border-white text-white';
        break;
      case 'blank' :
        classes += ' !shadow-none py-0 !bg-transparent !px-0 !min-w-0 text-secondary underline';
        break;
    }

    return classes;
  });

  const getIconColour = computed(() => {
    switch (props.theme) {
      case 'primary':
      case 'secondary' :
      case 'outline-white' :
          return 'text-primary bg-white';
      case 'grey' :
      case 'white' :
        return 'text-white bg-primary';
    }
  });

</script>
